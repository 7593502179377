import {FunctionalComponent, h} from 'preact';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useState} from "preact/hooks";
import Drawer from '@mui/material/Drawer';
import {ActiveComponent} from "../../rich-media-tag/components/state";
import Button from "@mui/material/Button/Button";
import IconButton from '@mui/material/IconButton/IconButton';
import CloseIcon from '@mui/icons-material/Close';

interface InfoBox {
    title: string;
    description: string;
}

interface Props {
    infoBox: InfoBox;
}

const InfoBox: FunctionalComponent<Props> = (props: Props) => {

    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = (expanded: boolean) => {
        setExpanded(expanded);
    };

    return (
        <div>
            <div
                onClick={() => handleExpandClick(true)}
                style={{
                    fontSize: '1em',
                    fontWeight: 500,
                    padding: '0.8em 0',
                    color: 'black',
                    textTransform: 'uppercase'
                }}>{props.infoBox.title}
                <ExpandMoreIcon style={{color: "black", float: 'right', margin: 'auto', transform: 'rotate(270deg)'}}/>
            </div>

            <Drawer
                anchor={'bottom'}
                open={expanded}
                onClose={() => handleExpandClick(false)}
            >
                <div style={{
                    backgroundColor: 'black',
                    height: '3em',
                    fontSize: '1em',
                    fontWeight: 500,
                    color: 'white',
                    textTransform: 'uppercase',
                    display: 'flex',
                    alignItems: 'center',
                }}>
                    <span style={{marginLeft: '1em'}}>
                        {props.infoBox.title}
                    </span>
                    <div style={{
                        float: 'right',
                        margin: 'auto',
                        marginRight: '0em',
                    }}>
                        <IconButton color="default" aria-label="back" size={"large"}
                                    onClick={() => {
                                        handleExpandClick(false);
                                    }}
                                    style={{
                                        color: 'white',
                                    }}>
                            <CloseIcon/>
                        </IconButton>
                    </div>
                </div>
                <div style={{
                    padding: '1em 1em 0.5em',
                    height: '45vh',
                    overflowY: 'scroll',
                }}>
                    <span class={'info-box'} style={{whiteSpace: 'pre-line'}} dangerouslySetInnerHTML={{__html: props.infoBox.description}}/>
                </div>
            </Drawer>
        </div>

    );
};

export default InfoBox;
