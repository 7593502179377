import {FunctionalComponent, h} from 'preact';
import {useState, useEffect} from "preact/hooks";
import IconButton from '@mui/material/IconButton/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {ActiveComponent} from "../../rich-media-tag/components/state";

interface Props {
    data: any;
    cid: number;
    os: string;
    setActiveComponent: (c: ActiveComponent) => void;
    vidRef: any;
    setSelectedProductIndex: (i: number) => void;
    selectedProductIndex: number;
}

const Main: FunctionalComponent<Props> = (props: Props) => {

    const [selectedProductIndex, setSelectedProductIndex] = useState<number>(-1);
    const [isVideoLoaded, setIsVideoLoaded] = useState<boolean>(false);
    const [areImagesLoaded, setAreImagesLoaded] = useState<boolean>(false);
    const {data, cid, os} = props;

    useEffect(() => {
        data && Promise.all(Array.from(document.images).filter(img => !img.complete).map(img => new Promise(resolve => {
            img.onload = img.onerror = resolve;
        }))).then(() => {
            console.log("Images Loaded")
            setAreImagesLoaded(true);
            // @ts-ignore
            window.webkit.messageHandlers.observer.postMessage("TEMPO_IMAGES_LOADED");
        });
    }, [])

    useEffect(() => {
        if (isVideoLoaded && areImagesLoaded) {
            // @ts-ignore
            window.webkit.messageHandlers.observer.postMessage("TEMPO_ASSETS_LOADED");
        }
    }, [isVideoLoaded, areImagesLoaded])

    return (
        <div style={{position: 'absolute', width: '100%', height: '100%', background: 'white'}}>
            <div>
                <div style={{
                    position: 'absolute',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'white',
                    height: '80vh',
                    width: '100%',
                    zIndex: 1,
                }}>
                    {data.video_url ? <div>
                            {/*Prefetch thumbnail*/}
                            <img src={data.video_thumbnail_url} style={{display: 'none'}}/>
                            <video id="video"
                                   ref={props.vidRef}
                                   autoPlay={true}
                                   loop={true}
                                   playsInline={true}
                                   poster={data.video_thumbnail_url}
                                   onLoadedData={() => {
                                       console.log("Video Loaded")
                                       // @ts-ignore
                                       window.webkit.messageHandlers.observer.postMessage("TEMPO_VIDEO_LOADED");
                                       setIsVideoLoaded(true)
                                   }}
                                   style={{
                                       zIndex: 2,
                                       objectFit: 'contain',
                                       width: '100vw',
                                       // height: '80vh',
                                   }}>
                                <source type="video/mp4"
                                        src={data.video_url}/>
                                {/*src={data.video_url}/>*/}
                            </video>
                        </div> :
                        <img
                            src={data.image_url ? data.image_url : 'https://i.imgur.com/IKRFGH5.png'}
                            style={{
                                zIndex: 1,
                                objectFit: 'cover',
                                width: '100vw',
                                height: '80vh',
                                display: selectedProductIndex === -1 ? 'block' : 'none',
                            }}/>
                    }

                    <img
                        src={data.campaign_products[0].product.image_urls[0]}
                        style={{
                            zIndex: 1,
                            objectFit: 'contain',
                            maxWidth: '70%',
                            maxHeight: '60%',
                            marginTop: '11vh',
                            display: selectedProductIndex === 0 ? 'block' : 'none',
                        }}/>
                    <img
                        src={data.campaign_products[1].product.image_urls[0]}
                        style={{
                            zIndex: 1,
                            objectFit: 'contain',
                            maxWidth: '70%',
                            maxHeight: '60%',
                            marginTop: '11vh',
                            display: selectedProductIndex === 1 ? 'block' : 'none',
                        }}/>
                    <img
                        src={data.campaign_products[2].product.image_urls[0]}
                        style={{
                            zIndex: 1,
                            objectFit: 'contain',
                            maxWidth: '70%',
                            maxHeight: '60%',
                            marginTop: '11vh',
                            display: selectedProductIndex === 2 ? 'block' : 'none',
                        }}/>
                    <img
                        src={data.campaign_products[3].product.image_urls[0]}
                        style={{
                            zIndex: 1,
                            objectFit: 'contain',
                            maxWidth: '70%',
                            maxHeight: '60%',
                            marginTop: '11vh',
                            display: selectedProductIndex === 3 ? 'block' : 'none',
                        }}/>
                </div>
                <div style={{
                    position: 'absolute',
                    right: '10px',
                    top: '1.2em',
                    backgroundColor: 'rgba(255,255,255,0.5)',
                    borderRadius: '50%',
                    zIndex: 2,
                }}>
                    <IconButton color="default" aria-label="back" size={"small"}
                                onClick={() => {
                                    // @ts-ignore
                                    window.mixpanel.track('Close Ad');
                                    // @ts-ignore
                                    props.vidRef && props.vidRef.current && props.vidRef.current.pause();
                                    // @ts-ignore
                                    if (typeof Android !== "undefined" && Android !== null) {
                                        // @ts-ignore
                                        Android.closeAd();
                                    } else {
                                        // @ts-ignore
                                        window.webkit.messageHandlers.observer.postMessage("TEMPO_CLOSE_AD");
                                    }
                                }}
                                style={{
                                    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
                                    filter: 'drop-shadow(0px 0px 0px #000000)',
                                }}>
                        <CloseIcon/>
                    </IconButton>
                </div>
                <div style={{
                    position: 'absolute',
                    marginTop: 'absolute',
                    background: 'rgb(229, 229, 229, 0.8)',
                    borderRadius: '30px',
                    height: '2em',
                    width: 'max-content',
                    maxWidth: '90vw',
                    left: '50%',
                    // @ts-ignore
                    top: data.is_bottom_headline ? undefined : '10vh',
                    // @ts-ignore
                    bottom: data.is_bottom_headline ? '37vh' : undefined,
                    transform: 'translate(-50%, 0)',
                    display: 'flex',
                    alignItems: 'center',
                    zIndex: 4
                }}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        margin: '0 1em'
                    }}>
                        <img
                            src={data.logo_url}
                            style={{
                                maxWidth: '8em',
                                maxHeight: '1.7em',
                                float: 'left',
                                marginRight: '0.5em',
                                borderRadius: '2px',
                                objectFit: 'contain'
                            }}/>

                        <div style={{
                            minWidth: '30vw',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                            <span style={{
                                maxWidth: '70vw',
                                fontSize: '1em',
                                color: 'black',
                            }}>{data.headline}</span>
                        </div>
                    </div>
                </div>

                <div style={{
                    position: 'absolute',
                    width: '100vw',
                    bottom: 0,
                    borderTopLeftRadius: '12px',
                    borderTopRightRadius: '12px',
                    backgroundImage: `linear-gradient(${data.top_hex_color} , ${data.bottom_hex_color})`,
                    boxShadow: '0px -4.83762px 32.2508px rgba(0, 0, 0, 0.23)',
                    zIndex: 2
                }}>
                    <div style={{margin: '1.5em 1em 0.5em 1em', fontWeight: '500', color: 'black'}}>
                        <span>Tap to discover</span>
                        <span style={{float: "right"}}>Ad</span>
                    </div>
                    <div style={{
                        padding: '0.8em 1em 2.5em 1em',
                        display: 'grid',
                        gap: '0.5em',
                        gridTemplateColumns: 'repeat(4, 1fr)',
                        zIndex: 3,
                    }}>
                        {/*@ts-ignore*/}
                        {data.campaign_products.map((cp, index) =>
                            <IconButton style={{padding: 0}}
                                        onClick={() => {
                                            // @ts-ignore
                                            window.mixpanel.track(`Clicked product ${index}`);
                                            setSelectedProductIndex(index)
                                            props.vidRef && props.vidRef.current && props.vidRef.current.pause();
                                            // if(props.vidRef && props.vidRef.current){
                                            //     props.vidRef.current.volume = 0.1
                                            // }

                                            props.setSelectedProductIndex(index)
                                            props.setActiveComponent(index);
                                            // if (os === 'ios'){
                                            //     route(`/campaign/${cid}/product/${index}/${os}`)
                                            // } else{
                                            //     route(`/campaign/${cid}/product/${index}`)
                                            // }
                                        }}>
                                <div key={index}
                                     class={'box-container'}
                                     style={{
                                         backgroundColor: 'white',
                                         boxShadow: '0px 3.44904px 17.2452px rgba(0, 0, 0, 0.15)',
                                         borderRadius: '10px'
                                     }}>
                                    <img
                                        class={'box'}
                                        src={cp.product.images[0].url}
                                        style={{
                                            objectFit: 'contain',
                                        }}/>
                                </div>
                            </IconButton>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Main;
