import {FunctionalComponent, h} from 'preact';
import {useEffect, useState, useRef} from "preact/hooks";
import Main from './Main';
import Product from "./Product";
// import data2 from './sample'
import {ActiveComponent} from "../../rich-media-tag/components/state";

interface Props {
    cid: number;
    os: string;
}

// @ts-ignore
const Campaign: FunctionalComponent = (props: Props) => {
    const {cid, os} = props;
    const [data, setData] = useState(undefined);
    const vidRef = useRef(null);
    const [activeComponent, setActiveComponent] = useState<ActiveComponent>(ActiveComponent.Home);
    const [selectedProductIndex, setSelectedProductIndex] = useState<number>(0);

    useEffect(() => {
        // @ts-ignore
        // setData(data2)
        fetch(`https://tempo-campaign-assets.s3.us-east-1.amazonaws.com/data/${cid}.json`)
            .then(res => {
                return res.json();
            })
            .then(d => {
                setData(d.data.campaign[0]);
            })
    }, [])

    const setActiveComponentOnClick = (activeComponent: ActiveComponent): void => {
        setActiveComponent(activeComponent);
        if (activeComponent == ActiveComponent.Home) {
            document.body.style.overflow = "hidden"
            setSelectedProductIndex(0);
            // @ts-ignore
            vidRef && vidRef.current && vidRef.current.play();
        } else {
            document.body.style.overflow = "auto"
        }
    };

    return (
        <div>
            {data && (
                <div>
                    <div style={activeComponent === ActiveComponent.Home ? {} : {visibility: 'hidden'}}>
                        {/*@ts-ignore*/}
                        <Main setActiveComponent={setActiveComponentOnClick}
                              os={os} cid={cid}
                              data={data}
                              vidRef={vidRef}
                              selectedProductIndex={selectedProductIndex}
                              setSelectedProductIndex={setSelectedProductIndex}
                        />
                    </div>
                    <div style={activeComponent !== ActiveComponent.Home ? {} : {visibility: 'hidden'}}>
                        {/*@ts-ignore*/}
                        <Product os={os} cid={cid} data={data} vidRef={vidRef}
                                 pid={selectedProductIndex}
                                 setActiveComponent={setActiveComponentOnClick}
                        />
                    </div>
                    {/*<div style={activeComponent === ActiveComponent.Product1 ? {} : {visibility: 'hidden'}}>*/}
                    {/*    /!*@ts-ignore*!/*/}
                    {/*    <Product os={os} cid={cid} data={data} vidRef={vidRef}*/}
                    {/*             pid={selectedProductIndex}*/}
                    {/*             setActiveComponent={setActiveComponentOnClick}*/}
                    {/*    />*/}
                    {/*</div>*/}
                    {/*<div style={activeComponent === ActiveComponent.Product2 ? {} : {visibility: 'hidden'}}>*/}
                    {/*    /!*@ts-ignore*!/*/}
                    {/*    <Product os={os} cid={cid} data={data} vidRef={vidRef}*/}
                    {/*             pid={selectedProductIndex}*/}
                    {/*             setActiveComponent={setActiveComponentOnClick}*/}
                    {/*    />*/}
                    {/*</div>*/}
                    {/*<div style={activeComponent === ActiveComponent.Product3 ? {} : {visibility: 'hidden'}}>*/}
                    {/*    /!*@ts-ignore*!/*/}
                    {/*    <Product os={os} cid={cid} data={data} vidRef={vidRef}*/}
                    {/*             pid={selectedProductIndex}*/}
                    {/*             setActiveComponent={setActiveComponentOnClick}*/}
                    {/*    />*/}
                    {/*</div>*/}
                </div>
            )}
        </div>
    );
};

export default Campaign;
