import {FunctionalComponent, h} from 'preact';
import {useEffect} from "preact/hooks";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const ThankYou: FunctionalComponent = () => {

    useEffect(() => {
        // @ts-ignore
        window.mixpanel.track("Website Home Page", {
            "url": window.location.href
        });
    }, [])

    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            height: '50%',
            padding: '2em'
        }}>
            <div style={{
                position: 'absolute',
                right: '3px',
                top: '3px',
                backgroundColor: 'rgba(255,255,255,0.5)',
                borderRadius: '50%',
                zIndex: 2,
            }}>
                <IconButton color="default" aria-label="back" size={"small"}
                            onClick={() => {
                                // @ts-ignore
                                window.mixpanel.track('Close Ad');

                                // @ts-ignore
                                if (typeof Android !== "undefined" && Android !== null) {
                                    // @ts-ignore
                                    Android.closeAd();
                                } else {
                                    // @ts-ignore
                                    window.webkit.messageHandlers.observer.postMessage("TEMPO_CLOSE_AD");
                                }
                            }}
                            style={{
                                boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
                                filter: 'drop-shadow(0px 0px 0px #000000)',
                            }}>
                    <CloseIcon/>
                </IconButton>
            </div>

            <div style={{marginTop: '3em'}}>
                <CheckCircleOutlineIcon color={'success'} fontSize={'large'} sx={{fontSize: '4em'}}/>
            </div>
            <h2 style={{fontSize: '2em'}}>
                Order Confirmed
            </h2>
            <span style={{fontSize: '1.3em', textAlign: 'center', marginTop: '1em'}}>
                Thank you, your payment has been successful. We will send  you a confirmation email in some time.
            </span>
            <span style={{fontSize: '1em', marginTop: '3em'}}>
                You may close this window now.
            </span>
        </div>
    );
};

export default ThankYou;
