import {FunctionalComponent, h} from 'preact';
import {useEffect} from "preact/hooks";

const Home: FunctionalComponent = () => {

    useEffect(() => {
        // @ts-ignore
        window.mixpanel.track("Website Home Page", {
            "url": window.location.href
        });
    }, [])

    return (
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '50%'}}>
            <span>
                We're an <a href="https://tempoplatform.com" target={"_blank"}>Ad Network</a> that hosts brand Ads.
            </span>
        </div>
    );
};

export default Home;
