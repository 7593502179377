import {FunctionalComponent, h} from 'preact';
import {useEffect, useRef, useState} from "preact/hooks";
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';


interface Props {
    name: string;
    price: string;
    quantity: string;
}

const TEMPO_DOMAIN = 'https://brands.tempoplatform.com';

const Checkout: FunctionalComponent<Props> = (props: Props) => {
    const {name, price, quantity} = props;
    console.log(name, price, quantity);
    const [fullName, _setFullName] = useState<string>('');
    const [email, _setEmail] = useState<string>('');
    const [line1, _setLine1] = useState<string>('');
    const [line2, _setLine2] = useState<string>('');
    const [city, _setCity] = useState<string>('');
    const [zip, _setZip] = useState<string>('');
    const [state, _setState] = useState<string>('');
    const nameRef = useRef(fullName);
    const emailRef = useRef(email);
    const line1Ref = useRef(line1);
    const line2Ref = useRef(line2);
    const cityRef = useRef(city);
    const zipRef = useRef(zip);
    const stateRef = useRef(state);
    const [isBuying, setIsBuying] = useState(false);

    const setName = (data: string) => {
        nameRef.current = data;
        _setFullName(data);
    };
    const setEmail = (data: string) => {
        emailRef.current = data;
        _setEmail(data);
    };
    const setLine1 = (data: string) => {
        line1Ref.current = data;
        _setLine1(data);
    };
    const setLine2 = (data: string) => {
        line2Ref.current = data;
        _setLine2(data);
    };
    const setCity = (data: string) => {
        cityRef.current = data;
        _setCity(data);
    };
    const setZip = (data: string) => {
        zipRef.current = data;
        _setZip(data);
    };
    const setState = (data: string) => {
        stateRef.current = data;
        _setState(data);
    };

    // @ts-ignore
    const stripe = window.Stripe('pk_live_51JvmHRHml7UVwjdx6hkCwb7abvyzju6qIGEfgwXokkzYoNueeeJDW9tvqn0SOJ7QpIwsRMhFbzRGeHvZBlhpZvJ700jRjZ6KBq', {
        apiVersion: "2020-08-27",
    });

    useEffect(() => {
        // @ts-ignore
        window.mixpanel.track("Website Checkout Page", {
            "url": window.location.href
        });

        createPaymentIntent().then(function (response) {
            // @ts-ignore
            window.mixpanel.track("Website Payment Intent Created");

            let cs = response.paymentIntent.client_secret;
            const options = {
                clientSecret: cs,
                appearance: {},
            };

            // Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in step 2
            const elements = stripe.elements(options);

            // Create and mount the Payment Element
            // @ts-ignore
            const paymentElement = elements.create('payment');
            paymentElement.mount('#payment-element');

            //    HANDLE SUBMIT

            const form = document.getElementById('payment-form');

            // @ts-ignore
            form.addEventListener('submit', async (event) => {
                // @ts-ignore
                window.mixpanel.track("Website Pay Button Clicked", {
                    name: nameRef.current,
                    email: emailRef.current,
                });

                event.preventDefault();
                setIsBuying(true);
                const {error} = await stripe.confirmPayment({
                    //`Elements` instance that was used to create the Payment Element
                    // @ts-ignore
                    elements,
                    confirmParams: {
                        return_url: `${TEMPO_DOMAIN}/thank-you`,
                        shipping: {
                            name: `${nameRef.current} | ${emailRef.current}`,
                            address: {
                                line1: line1Ref.current,
                                line2: line2Ref.current,
                                city: cityRef.current,
                                state: stateRef.current,
                                postal_code: zipRef.current,
                                country: "US",
                            },
                        }
                    },
                });

                if (error) {
                    // @ts-ignore
                    window.mixpanel.track("Website Payment Failure", {
                        error: error.message,
                    });
                    setIsBuying(false);
                    // This point will only be reached if there is an immediate error when
                    // confirming the payment. Show error to your customer (for example, payment
                    // details incomplete)
                    const messageContainer = document.querySelector('#error-message');
                    console.log(error.message)

                } else {
                    setIsBuying(false);
                    // @ts-ignore
                    window.mixpanel.track("Website Payment Success");
                    // Your customer will be redirected to your `return_url`. For some payment
                    // methods like iDEAL, your customer will be redirected to an intermediate
                    // site first to authorize the payment, then redirected to the `return_url`.
                }
            });
        });
    }, [])

    // @ts-ignore
    const createPaymentIntent = async () => {
        try {
            const response = await fetch('https://tempo-stripe-demo.herokuapp.com/payment_intents', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    currency: 'USD',
                    price: price,
                    quantity: quantity,
                    productName: name,
                    campaignId: 1,
                    productId: 0,
                }),
            });
            const data = await response.json();
            if (data.error) {
                return {error: data.error};
            } else {
                return data;
            }
        } catch (err) {
            // @ts-ignore
            return {error: err.message};
        }
    }

    return (
        <div>
            <div style={{
                padding: '1em', display: 'flex', justifyContent: 'center', alignItems: 'center',
                flexDirection: 'column',
                marginTop: '22px',
            }}>
                <div style={{
                    color: 'rgba(26,26,26,.6)',
                    fontWeight: 500,
                    fontSize: '16px',
                    textAlign: 'center',
                }}>{name}</div>
                <div style={{
                    fontSize: '22px',
                    fontWeight: '600',
                    boxSizing: 'border-box',
                    marginTop: '4px',
                    fontVariantNumeric: 'tabular-nums',
                    fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Ubuntu,sans-serif'
                }}>${parseFloat(price).toFixed(2)}</div>
            </div>
            <form id="payment-form" style={{padding: '1em'}}>
                <h2 style={{fontWeight: '500', fontSize: '16px'}}>Shipping Information</h2>
                <div style={{
                    color: 'rgba(0, 0, 0, 0.6)',
                    paddingBottom: '0.5em',
                    fontSize: '13px',
                    fontWeight: 500,
                }}>
                    Email
                </div>
                <TextField id="email" fullWidth required size="small" variant="outlined"
                           onChange={(e) => {
                               setEmail(e.target.value)
                           }}
                           style={{marginBottom: '1em'}}
                />

                <div style={{
                    color: 'rgba(0, 0, 0, 0.6)',
                    paddingBottom: '1em',
                    fontSize: '13px',
                    fontWeight: 500,
                }}>
                    Shipping Address
                </div>
                <div style={{marginBottom: '1em'}}>
                    <TextField id="name" fullWidth label="Full Name" required value={fullName} size="small" variant="outlined"
                               style={{marginBottom: '0.5em'}}
                               onChange={(e) => setName(e.target.value)}/>
                    <TextField id="line1" fullWidth required label="Address Line 1" size="small" variant="outlined"
                               style={{marginBottom: '0.5em'}}
                               onChange={(e) => setLine1(e.target.value)}/>
                    <TextField id="line2" fullWidth label="Address Line 2" size="small" variant="outlined"
                               style={{marginBottom: '0.5em'}}
                               onChange={(e) => setLine2(e.target.value)}/>
                    <TextField id="city" fullWidth required label="City" size="small" variant="outlined"
                               style={{marginBottom: '0.5em'}}
                               onChange={(e) => setCity(e.target.value)}/>
                    <TextField id="zip" fullWidth type='number' required label="ZIP" size="small" variant="outlined"
                               style={{marginBottom: '0.5em'}}
                               onChange={(e) => setZip(e.target.value)}/>

                    <FormControl fullWidth>

                        {/*<InputLabel variant="outlined" htmlFor="uncontrolled-native">*/}
                        {/*    State*/}
                        {/*</InputLabel>*/}
                        {/*<NativeSelect*/}
                        {/*    defaultValue={30}*/}
                        {/*    variant="outlined"*/}
                        {/*    inputProps={{*/}
                        {/*        name: 'state',*/}
                        {/*        id: 'uncontrolled-native',*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    <option value={10}>Ten</option>*/}
                        {/*    <option value={20}>Twenty</option>*/}
                        {/*    <option value={30}>Thirty</option>*/}
                        {/*</NativeSelect>*/}

                        {/*@ts-ignore*/}
                        <InputLabel id="state-select-label" size="small" htmlFor="uncontrolled-native">State</InputLabel>
                        <Select
                            native
                            value={state}
                            size="small"
                            fullWidth
                            sx={{minWidth: 110}}
                            style={{marginBottom: '0.5em'}}
                            labelId="state-select-label"
                            id="state-select"
                            variant="outlined"
                            onChange={(e) => setState(e.target.value)}
                        >
                            <option value="AL">Alabama</option>
                            <option value="AK">Alaska</option>
                            <option value="AS">American Samoa</option>
                            <option value="AZ">Arizona</option>
                            <option value="AR">Arkansas</option>
                            <option value="AA">Armed Forces (AA)</option>
                            <option value="AE">Armed Forces (AE)</option>
                            <option value="AP">Armed Forces (AP)</option>
                            <option value="CA">California</option>
                            <option value="CO">Colorado</option>
                            <option value="CT">Connecticut</option>
                            <option value="DE">Delaware</option>
                            <option value="DC">District of Columbia</option>
                            <option value="FL">Florida</option>
                            <option value="GA">Georgia</option>
                            <option value="GU">Guam</option>
                            <option value="HI">Hawaii</option>
                            <option value="ID">Idaho</option>
                            <option value="IL">Illinois</option>
                            <option value="IN">Indiana</option>
                            <option value="IA">Iowa</option>
                            <option value="KS">Kansas</option>
                            <option value="KY">Kentucky</option>
                            <option value="LA">Louisiana</option>
                            <option value="ME">Maine</option>
                            <option value="MH">Marshall Islands</option>
                            <option value="MD">Maryland</option>
                            <option value="MA">Massachusetts</option>
                            <option value="MI">Michigan</option>
                            <option value="FM">Micronesia</option>
                            <option value="MN">Minnesota</option>
                            <option value="MS">Mississippi</option>
                            <option value="MO">Missouri</option>
                            <option value="MT">Montana</option>
                            <option value="NE">Nebraska</option>
                            <option value="NV">Nevada</option>
                            <option value="NH">New Hampshire</option>
                            <option value="NJ">New Jersey</option>
                            <option value="NM">New Mexico</option>
                            <option value="NY">New York</option>
                            <option value="NC">North Carolina</option>
                            <option value="ND">North Dakota</option>
                            <option value="MP">Northern Mariana Islands</option>
                            <option value="OH">Ohio</option>
                            <option value="OK">Oklahoma</option>
                            <option value="OR">Oregon</option>
                            <option value="PW">Palau</option>
                            <option value="PA">Pennsylvania</option>
                            <option value="PR">Puerto Rico</option>
                            <option value="RI">Rhode Island</option>
                            <option value="SC">South Carolina</option>
                            <option value="SD">South Dakota</option>
                            <option value="TN">Tennessee</option>
                            <option value="TX">Texas</option>
                            <option value="UT">Utah</option>
                            <option value="VT">Vermont</option>
                            <option value="VI">Virgin Islands</option>
                            <option value="VA">Virginia</option>
                            <option value="WA">Washington</option>
                            <option value="WV">West Virginia</option>
                            <option value="WI">Wisconsin</option>
                            <option value="WY">Wyoming</option>
                        </Select>
                    </FormControl>
                    <TextField id="country" fullWidth disabled value={'United States'} size="small"
                               variant="outlined"/>
                </div>
                <h2 style={{fontWeight: '500', fontSize: '16px'}}>Payment Details</h2>
                <div id="payment-element">
                </div>
                <div style={{marginBottom: '1.7em'}}>
                    <button id="submit" style={{
                        margin: '0.7em 0 0 0', width: '100%',
                        textTransform: "none",
                        fontWeight: '500',
                        fontSize: 'large',
                        borderRadius: '10px',
                        boxShadow: '0px 6.45015px 16.1254px rgba(0, 0, 0, 0.2)',
                        color: 'white',
                        backgroundColor: 'black',
                        padding: '8px 22px',
                        lineHeight: 1.75,
                        border: 'none',
                        transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'

                    }}>{isBuying ? 'Paying...' : 'Pay'}</button>
                </div>
                <div id="error-message">
                </div>
            </form>
        </div>
    );
};

export default Checkout;
