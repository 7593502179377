import {FunctionalComponent, h} from 'preact';
import IconButton from '@mui/material/IconButton';
import InfoBox from "../../src/components/InfoBox";
import Button from '@mui/material/Button/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import {Splide, SplideSlide} from '@splidejs/react-splide';
import {route} from 'preact-router';
import {useEffect, useState, useRef} from "preact/hooks";
import Snackbar from '@mui/material/Snackbar/Snackbar';
import Alert from '@mui/material/Alert/Alert';
import InfoBox2 from "../../src/components/InfoBox2";
import Divider from "@mui/material/Divider";
import Grid from '@mui/material/Grid';
import {ActiveComponent} from "../../rich-media-tag/components/state";
import ProductSelect from "../../src/components/ProductSelect";

const TEMPO_DOMAIN = 'https://brands.tempoplatform.com';

interface Props {
    pid: number;
    cid: number;
    os: string;
    data: any;
    setActiveComponent: (c: ActiveComponent) => void;
}

const Product: FunctionalComponent<Props> = (props: Props) => {
    const {pid, cid, data, os} = props;
    const product = data.campaign_products[pid].product;

    const [quantity, setQuantity] = useState(1);
    const [selectedColorIndex, setSelectedColorIndex] = useState(0);
    const [selectedSizeIndex, setSelectedSizeIndex] = useState(0);
    const [paymentIntentId, setPaymentIntentId] = useState(undefined);
    const [paymentRequest, setPaymentRequest] = useState(undefined);
    const [shouldShowCheckout, setShouldShowCheckout] = useState(true);
    const [isBuying, setIsBuying] = useState(false);
    const [clientSecret, setClientSecret] = useState(undefined);
    const [open, setOpen] = useState(false);
    const splideRef = useRef(null);

    const setColor = (index: number) => {
        setSelectedColorIndex(index)
        // @ts-ignore
        splideRef.current.go(0)
    };

    const handleCloseSnackBar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    useEffect(() => {
        // @ts-ignore
        window.mixpanel.track("Website Product Page", {
            "url": window.location.href
        });

        // @ts-ignore
        window.myEvent = new Event('myEvent');
        window.addEventListener('myEvent', function () {
            setShouldShowCheckout(true);
        }, false);

        // @ts-ignore
        const stripe = window.Stripe('pk_live_51JvmHRHml7UVwjdx6hkCwb7abvyzju6qIGEfgwXokkzYoNueeeJDW9tvqn0SOJ7QpIwsRMhFbzRGeHvZBlhpZvJ700jRjZ6KBq', {
            apiVersion: "2020-08-27",
        });

        // @ts-ignore
        const createPaymentIntent = async () => {
            try {
                const response = await fetch('https://tempo-stripe-demo.herokuapp.com/payment_intents', {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({
                        currency: 'USD',
                        price: product.price,
                        quantity: quantity,
                        productName: product.name,
                        campaignId: 1,
                        productId: 0,
                    }),
                });
                const data = await response.json();
                if (data.error) {
                    return {error: data.error};
                } else {
                    return data;
                }
            } catch (err) {
                // @ts-ignore
                return {error: err.message};
            }
        }

        if (os === 'ios') {
            var paymentRequest = stripe.paymentRequest({
                country: 'US',
                currency: 'usd',
                total: {
                    label: product.name,
                    amount: product.price * quantity * 100,
                },
                requestPayerName: true,
                requestPayerEmail: true,
                requestShipping: true,
                shippingOptions: [
                    {
                        id: 'free',
                        label: 'Free Shipping',
                        detail: 'Delivery within 5 days',
                        amount: 0,
                    }],
            });
            setPaymentRequest(paymentRequest);
            var elements = stripe.elements();
            var prButton = elements.create('paymentRequestButton', {
                paymentRequest: paymentRequest,
                style: {
                    paymentRequestButton: {
                        height: '52px',
                        borderRadius: '6px',
                    },
                },
            });

            // @ts-ignore
            paymentRequest.canMakePayment().then(function (result) {
                if (result) {
                    prButton.mount('#payment-request-button');
                    setShouldShowCheckout(false);
                    // @ts-ignore
                    window.mixpanel.track("Native Pay Loaded");
                } else {
                    setShouldShowCheckout(true);
                    // @ts-ignore
                    window.document.getElementById('payment-request-button').style.display = 'none';
                    // @ts-ignore
                    window.mixpanel.track("Native Pay Not Loaded");
                }
            });

            createPaymentIntent().then(function (response) {
                setPaymentIntentId(response.paymentIntent.id);

                // @ts-ignore
                paymentRequest.on('paymentmethod', function (ev) {
                    // Confirm the PaymentIntent without handling potential next actions (yet).
                    stripe.confirmCardPayment(
                        response.paymentIntent.client_secret,
                        {payment_method: ev.paymentMethod.id},
                        {handleActions: false}
                        // @ts-ignore
                    ).then(function (confirmResult) {
                        if (confirmResult.error) {
                            // Report to the browser that the payment failed, prompting it to
                            // re-show the payment interface, or show an error message and close
                            // the payment interface.
                            ev.complete('fail');
                        } else {
                            // Report to the browser that the confirmation was successful, prompting
                            // it to close the browser payment method collection interface.
                            ev.complete('success');
                            // Check if the PaymentIntent requires any actions and if so let Stripe.js
                            // handle the flow. If using an API version older than "2019-02-11"
                            // instead check for: `paymentIntent.status === "requires_source_action"`.
                            if (confirmResult.paymentIntent.status === "requires_action") {
                                // Let Stripe.js handle the rest of the payment flow.
                                // @ts-ignore
                                stripe.confirmCardPayment(clientSecret).then(function (result) {
                                    setOpen(true)
                                    if (result.error) {
                                        // @ts-ignore
                                        window.mixpanel.track("Payment Error", result.error);
                                    } else {
                                        // @ts-ignore
                                        window.mixpanel.track("Payment Warning", result);
                                    }
                                });
                            } else {
                                // @ts-ignore
                                window.mixpanel.track('Website Buy Now Complete', {
                                    productIndex: pid,
                                    product_name: product.name,
                                    product_price: product.price,
                                });
                                // @ts-ignore
                                window.webkit.messageHandlers.observer.postMessage(`${TEMPO_DOMAIN}/thank-you`);
                                // route(`${TEMPO_DOMAIN}/thank-you`)
                                window.location.href = `${TEMPO_DOMAIN}/thank-you`
                            }
                        }
                    });
                })
            });

        } else {
            createPaymentIntent().then(function (response) {
                setClientSecret(response.paymentIntent.client_secret);
                setPaymentIntentId(response.paymentIntent.id);
            });
        }
    }, [])

    const updateQuantity = (quantity: number) => {
        setQuantity(quantity);
        if (paymentIntentId && paymentRequest) {
            updatePaymentIntentQuantity(paymentIntentId, quantity,).then(function (response) {
                if (response.error) {
                    console.error(response.error);
                    return;
                }
            });
            // @ts-ignore
            paymentRequest.update({
                total: {
                    label: product.name,
                    amount: product.price * quantity * 100,
                }
            });
        }
    }

    // @ts-ignore
    const updatePaymentIntentQuantity = async (paymentIntent, quantity) => {
        try {
            const response = await fetch(
                `https://tempo-stripe-demo.herokuapp.com/payment_intents/${paymentIntent}/update_quantity`,
                {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({
                        price: product.price,
                        quantity: quantity,
                        // campaignId: product.campaignId,
                        // productId: product.id,
                        campaignId: 1,
                        productId: 0,
                    }),
                }
            );
            const data = await response.json();
            if (data.error) {
                return {error: data.error};
            } else {
                return data;
            }
        } catch (err) {
            // @ts-ignore
            return {error: err.message};
        }
    }

    return (
        <div style={{
            position: 'absolute', width: '100%', height: '100%',
            background: 'white', display: 'flex', flexFlow: "column"
        }}>
            <div>
                <div>
                    {/*<div style={{display: 'flex', alignItems: 'center', height: '11vh'}}>*/}
                    <Grid container spacing={0}
                          style={{display: 'flex', alignItems: 'center', height: '9.5vh', marginTop: '1.25em'}}>
                        <Grid item xs={2}>
                            <div style={{
                                backgroundColor: 'white',
                                borderRadius: '50%',
                                marginLeft: '1em',
                                zIndex: 2,
                            }}>
                                <IconButton color="default" aria-label="back" size={"small"}
                                            onClick={() => {
                                                // @ts-ignore
                                                window.mixpanel.track('Back to Home');
                                                props.setActiveComponent(ActiveComponent.Home)
                                                // if (os === 'ios'){
                                                //     route(`/campaign/${cid}/${os}`)
                                                // } else{
                                                //     route(`/campaign/${cid}`)
                                                // }
                                            }}
                                            style={{
                                                boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
                                                filter: 'drop-shadow(0px 0px 0px #000000)',
                                            }}>
                                    <ArrowBackIcon/>
                                </IconButton>
                            </div>
                        </Grid>
                        <Grid item xs={7}>
                            <div style={{
                                width: '55vw',
                                fontSize: '1.2em',
                                fontWeight: '500',
                                marginLeft: '1em',
                                marginRight: '0.5em',
                                color: 'black',
                            }}>{data.brand_name}</div>
                        </Grid>
                        <Grid item xs={3}>
                            <div style={{
                                display: 'flex',
                                marginRight: '1em',
                                float: 'right',
                                flexDirection: 'row',
                            }}>
                                <div style={{
                                    backgroundColor: 'white',
                                    borderRadius: '50%',
                                    zIndex: 2,
                                }}>
                                    <IconButton color="default" aria-label="back" size={"medium"}
                                                style={{
                                                    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
                                                    width: '34px',
                                                    height: '34px',
                                                    filter: 'drop-shadow(0px 0px 0px #000000)',
                                                }}
                                                onClick={() => {
                                                    // @ts-ignore
                                                    window.mixpanel.track('Initiated Share Ad');
                                                    if (navigator.share) {
                                                        navigator.share({
                                                            title: product.name,
                                                            text: product.url,
                                                            url: product.url,
                                                        })
                                                            .then(() => {
                                                                // @ts-ignore
                                                                window.mixpanel.track('Share Ad', {
                                                                    url: product.url
                                                                });
                                                            })
                                                            .catch((error) => {
                                                                // @ts-ignore
                                                                window.mixpanel.track('Share Ad Cancelled');
                                                            });
                                                        // @ts-ignore
                                                    } else if (typeof Android !== "undefined" && Android !== null) {
                                                        // @ts-ignore
                                                        Android.nativeShare(product.name, product.url)
                                                    }
                                                }}>
                                        <img style={{aspectRatio: '1', width: '16px', height: '16px'}} alt="edit"
                                             src="/assets/share.svg"/>
                                    </IconButton>
                                </div>
                            </div>
                        </Grid>
                        {/*<div style={{*/}
                        {/*    position: 'absolute',*/}
                        {/*    right: '10px',*/}
                        {/*    top: '1.2em',*/}
                        {/*    backgroundColor: 'rgba(255,255,255,0.5)',*/}
                        {/*    borderRadius: '50%',*/}
                        {/*    zIndex: 2,*/}
                        {/*}}>*/}
                        {/*    <IconButton color="default" aria-label="back" size={"small"}*/}
                        {/*                onClick={() => {*/}
                        {/*                    // @ts-ignore*/}
                        {/*                    window.mixpanel.track('Close Ad');*/}
                        {/*                    // @ts-ignore*/}
                        {/*                    props.vidRef && props.vidRef.current && props.vidRef.current.pause();*/}
                        {/*                    // @ts-ignore*/}
                        {/*                    if (typeof Android !== "undefined" && Android !== null) {*/}
                        {/*                        // @ts-ignore*/}
                        {/*                        Android.closeAd();*/}
                        {/*                    } else {*/}
                        {/*                        // @ts-ignore*/}
                        {/*                        window.webkit.messageHandlers.observer.postMessage("TEMPO_CLOSE_AD");*/}
                        {/*                    }*/}
                        {/*                }}*/}
                        {/*                style={{*/}
                        {/*                    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',*/}
                        {/*                    filter: 'drop-shadow(0px 0px 0px #000000)',*/}
                        {/*                }}>*/}
                        {/*        <CloseIcon/>*/}
                        {/*    </IconButton>*/}
                        {/*</div>*/}
                        {/*</div>*/}
                    </Grid>
                </div>
            </div>

            <div style={{display: 'flex', justifyContent: 'center'}}>
                <div style={{
                    width: '100vw',
                    borderTop: '0.1px solid #E2E2E2',
                    background: '#868686',
                }}/>
            </div>

            <div>
                <Splide
                    // @ts-ignore
                    ref={splideRef}
                    onMove={(sp, index) => {
                        // @ts-ignore
                        window.mixpanel.track("Image Slide Viewed", {
                            "Image Index": index,
                            "Product Index": index,
                        });
                    }}
                >
                    {/*@ts-ignore*/}
                    {product.images.filter((el, i) => product.colors[selectedColorIndex].imageIndices.some(j => i === j)).map((image, i) =>
                        <SplideSlide key={i}>
                            <div style={{
                                display: 'flex',
                                background: 'white',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                                height: '40vh'
                            }}>
                                <img style={
                                    image.shouldCenterWithBorder ? {
                                        zIndex: 0,
                                        objectFit: 'contain',
                                        maxWidth: '80%',
                                        maxHeight: '70%'
                                    } : {
                                        zIndex: 0,
                                        objectFit: 'contain',
                                        width: '100vw'
                                    }
                                }
                                     src={image.url}
                                     alt="Image 1"/>
                            </div>
                        </SplideSlide>)
                    }
                </Splide>
                <div style={{
                    height: '1.65em',
                    marginBottom: '0.75em',
                    backgroundColor: 'white',
                    zIndex: 2,
                }}/>
            </div>

            <div style={{
                width: '100vw',
                backgroundColor: 'white',
                zIndex: 2
            }}>
                <div style={{padding: '0 1em'}}>
                    <div>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column'
                        }}>
                            <div style={{
                                fontWeight: '500',
                                fontSize: '15px',
                                color: 'black',
                                lineHeight: '20px'
                            }}>{product.name}
                            </div>
                            <div style={{
                                fontWeight: '400',
                                fontSize: '13px',
                                color: 'black',
                                lineHeight: '20px'
                            }}>{data.brand_name}</div>

                            {product.price && <div style={{
                                fontSize: '1em',
                                fontWeight: '500',
                                marginTop: '3px',
                                marginBottom: '0.6em',
                                color: 'black',
                            }}>${(product.price * quantity).toFixed(2)}
                            </div>}
                        </div>
                        <div style={{clear: "both"}}>
                        </div>
                    </div>
                </div>
            </div>

            <ProductSelect colors={product.colors} selectedColorIndex={selectedColorIndex}
                           setSelectedColorIndex={setColor} selectedSizeIndex={selectedSizeIndex}
                           setSelectedSizeIndex={setSelectedSizeIndex} sizes={product.sizes}
            />

            <div style={{
                backgroundColor: 'white',
                width: '100vw',
            }}>
                <div style={{padding: '0 1em', marginBottom: '0.5em'}}>
                    <div>
                        <div id="payment-request-button"
                             style={{
                                 display: `${os === 'ios' ? 'block' : 'none'}`,
                             }}
                             onClick={() => {
                                 // @ts-ignore
                                 window.mixpanel.track('Website Buy Now Click');
                             }}/>
                        <Button style={{
                            margin: '0.7em 0 0 0', width: '100%',
                            display: `${shouldShowCheckout && os !== 'ios' ? 'block' : 'none'}`,
                            backgroundImage: 'url(https://www.gstatic.com/instantbuy/svg/dark_gpay.svg)',
                            backgroundColor: '#000',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center center',
                            backgroundSize: 'contain',
                            backgroundOrigin: 'content-box',
                            height: '47.5px',
                            border: '0px',
                            padding: '12px 24px 10px',
                            cursor: 'pointer',
                            textTransform: "none",
                            fontWeight: '500',
                            fontSize: 'large',
                            borderRadius: '10px',
                            boxShadow: '0px 6.45015px 16.1254px rgba(0, 0, 0, 0.2)'
                        }} variant="contained"
                                size="large"
                                color="secondary"
                                disabled={clientSecret === undefined}
                                onClick={() => {
                                    setIsBuying(true);
                                    // @ts-ignore
                                    window.mixpanel.track('Website Buy Now', {
                                        product_url: product.url,
                                        product_price: product.price,
                                    });

                                    // @ts-ignore
                                    if (typeof Android !== "undefined" && Android !== null) {
                                        // @ts-ignore
                                        Android.launchGooglePay(clientSecret);
                                    } else {
                                        alert("Not viewing in webview");
                                    }
                                }}/>
                    </div>
                </div>
            </div>

            <div style={{
                padding: '0.5em 1em 2em',
            }}>
                <div>
                    {/*@ts-ignore*/}
                    {product.info_boxes.map((box, i) =>
                        <div>
                            <InfoBox2 infoBox={box}/>
                            {<Divider/>}
                        </div>
                    )}
                </div>
            </div>

            <Snackbar open={open} autoHideDuration={6000} onClose={handleCloseSnackBar}>
                <Alert onClose={handleCloseSnackBar} severity="warning"
                       sx={{width: '100%'}}>
                    Sorry, your payment was not successful. Please try again with a different payment method.
                </Alert>
            </Snackbar>
        </div>
    )
        ;
};

export default Product;
