import {FunctionalComponent, h} from 'preact';

import Router from 'preact-router';
import theme from "../src/theme";
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import Home from "./components/Home";
import Campaign from "./components/Campaign";
import PreviewCampaign from "./components/PreviewCampaign";
import ThankYou from "./components/ThankYou";
import Checkout from "./components/Checkout";

const App: FunctionalComponent = () => {

    return (
        <ThemeProvider theme={theme}>
            <Router>
                <PreviewCampaign path="/preview/main"/>
                <PreviewCampaign path="/preview/product/:pid"/>
                <Campaign path="/campaign/:cid"/>
                <Campaign path="/campaign/:cid/:os?"/>
                <Campaign path="/campaign/:cid/product/:pid"/>
                <Campaign path="/campaign/:cid/product/:pid/:os?"/>
                {/*@ts-ignore*/}
                <Checkout path="/checkout/name/:name/price/:price/quantity/:quantity"/>
                <ThankYou path="/thank-you" />
                <Home default />
            </Router>
        </ThemeProvider>

    );
};

export default App;
