import {FunctionalComponent, h} from 'preact';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useState} from "preact/hooks";
import Drawer from '@mui/material/Drawer';
import {ActiveComponent} from "../../rich-media-tag/components/state";
import Button from "@mui/material/Button/Button";
import IconButton from '@mui/material/IconButton/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid/Grid';
import Divider from '@mui/material/Divider/Divider';

interface Color {
    id: string
    name: string
    hexCode: string
}

interface Size {
    id: string
    name: string
}

interface Props {
    colors: Array<Color>
    selectedColorIndex: number
    setSelectedColorIndex: any
    sizes: Array<Size>
    selectedSizeIndex: number
    setSelectedSizeIndex: any
}

const ProductSelect: FunctionalComponent<Props> = (props: Props) => {

    const [colorExpanded, setColorExpanded] = useState(false);
    const [sizeExpanded, setSizeExpanded] = useState(false);

    const handleColorExpandClick = (expanded: boolean) => {
        setColorExpanded(expanded);
    };
    const handleSizeExpandClick = (expanded: boolean) => {
        setSizeExpanded(expanded);
    };

    return (
        <div>

            <div style={{margin: '0em 1em 1em'}}>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <div onClick={() => handleColorExpandClick(true)}
                             style={{
                                 border: '1px solid',
                                 borderRadius: '6px',
                                 display: 'flex',
                                 color: 'black',
                                 flexDirection: 'column',
                                 justifyContent: 'center',
                                 padding: '0.4em 1em',
                             }}>
                            <div style={{fontWeight: '400'}}>Color</div>
                            <div style={{
                                color: '#717171',
                                fontWeight: '500'
                            }}>{props.colors[props.selectedColorIndex].name}</div>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div onClick={() => handleSizeExpandClick(true)}
                             style={{
                                 border: '1px solid',
                                 borderRadius: '6px',
                                 display: 'flex',
                                 color: 'black',
                                 flexDirection: 'column',
                                 justifyContent: 'center',
                                 padding: '0.4em 1em',
                             }}>
                            <div style={{fontWeight: '400'}}>Size</div>
                            <div style={{
                                color: '#717171',
                                fontWeight: '500'
                            }}>{props.sizes[props.selectedSizeIndex].name}</div>
                        </div>
                    </Grid>
                </Grid>
            </div>

            <Drawer
                anchor={'bottom'}
                open={colorExpanded}
                onClose={() => handleColorExpandClick(false)}
            >
                <div style={{
                    backgroundColor: 'black',
                    height: '3em',
                    fontSize: '1em',
                    fontWeight: 500,
                    color: 'white',
                    textTransform: 'uppercase',
                    display: 'flex',
                    alignItems: 'center',
                }}>
                    <span style={{marginLeft: '1em', textTransform: 'uppercase'}}>
                        Select Color
                    </span>
                    <div style={{
                        float: 'right',
                        margin: 'auto',
                        marginRight: '0em',
                    }}>
                        <IconButton color="default" aria-label="back" size={"large"}
                                    onClick={() => {
                                        handleColorExpandClick(false);
                                    }}
                                    style={{
                                        color: 'white',
                                    }}>
                            <CloseIcon/>
                        </IconButton>
                    </div>
                </div>
                <div style={{
                    padding: '0em 0em 0.5em',
                    height: '45vh',
                    overflowY: 'scroll',
                }}>
                    <div>
                        {props.colors.map((color, i) =>
                            <div
                                className={"select-option"}
                                key={color.id}
                                onClick={() => {
                                    props.setSelectedColorIndex(i)
                                    handleColorExpandClick(false)
                                }}>
                                <div style={{
                                    textTransform: "capitalize",
                                    padding: "1em 1em",
                                    display: "flex",
                                    alignItems: "center"
                                }}>
                                    <span className="select-dot-color" style={{backgroundColor: color.hexCode}}/>
                                    <span style={{paddingLeft: '1em'}}>{color.name}</span>
                                </div>
                                <Divider/>
                            </div>
                        )}
                    </div>
                </div>
            </Drawer>

            <Drawer
                anchor={'bottom'}
                open={sizeExpanded}
                onClose={() => handleSizeExpandClick(false)}
            >
                <div style={{
                    backgroundColor: 'black',
                    height: '3em',
                    fontSize: '1em',
                    fontWeight: 500,
                    color: 'white',
                    textTransform: 'uppercase',
                    display: 'flex',
                    alignItems: 'center',
                }}>
                    <span style={{marginLeft: '1em', textTransform: 'uppercase'}}>
                        Select Size
                    </span>
                    <div style={{
                        float: 'right',
                        margin: 'auto',
                        marginRight: '0em',
                    }}>
                        <IconButton color="default" aria-label="back" size={"large"}
                                    onClick={() => {
                                        handleSizeExpandClick(false);
                                    }}
                                    style={{
                                        color: 'white',
                                    }}>
                            <CloseIcon/>
                        </IconButton>
                    </div>
                </div>
                <div style={{
                    padding: '0em 0em 0.5em',
                    height: '45vh',
                    overflowY: 'scroll',
                }}>
                    <div>
                        {props.sizes.map((size, i) =>
                            <div
                                className={"select-option"}
                                key={size.id}
                                onClick={() => {
                                    props.setSelectedSizeIndex(i)
                                    handleSizeExpandClick(false)
                                }}>
                                <div style={{
                                    textTransform: "capitalize",
                                    padding: "1em 1em",
                                    display: "flex",
                                    alignItems: "center"
                                }}>
                                    <span>{size.name}</span>
                                </div>
                                <Divider/>
                            </div>
                        )}
                    </div>
                </div>
            </Drawer>
        </div>

    );
};

export default ProductSelect;
